import Loader from 'react-loader-spinner';

const Spinner = () => {
	return (
		<div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
			<Loader type="TailSpin" color="#f4c03b" height={110} width={110} />
		</div>
	);
};

export default Spinner;
