import { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Spinner from '../components/Spinner/Spinner';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import ScrollToTopIcon from '../components/ScrollToTop/ScrollToTopIcon';
import FloatingPhone from '../components/ScrollToTop/FloatingPhone';

import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';

const Home = lazy(() => import('../pages/Home'));
const About = lazy(() => import('../pages/About'));
const Contact = lazy(() => import('../pages/Contact'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Products = lazy(() => import('../pages/Products/Products'));

const AppRouter = () => {
	return (
		<Suspense fallback={<Spinner />}>
			<BrowserRouter>
				<Navigation />
				<ScrollToTop />
				<Switch>
					<Route path="/" component={Home} exact />
					<Route path="/about" component={About} />
					<Route path="/contact" component={Contact} />
					<Route path="/products" component={Products} />
					<Route component={NotFound} />
				</Switch>
				<ScrollToTopIcon />
				<FloatingPhone />
				<Footer />
			</BrowserRouter>
		</Suspense>
	);
};

export default AppRouter;
