import { BiPhoneCall } from 'react-icons/bi';
import styled from 'styled-components/macro';

const PhoneIcon = styled.div`
	position: fixed;
	left: 2%;
	bottom: 4%;
	width: 50px;
	height: 50px;
	font-size: 13px;
	border-radius: 50%;
	padding: 12px;
	z-index: 4;
	transition: all 0.4s;
	border: 1px solid rgba(167, 167, 167, 0.2);
	box-shadow: 0px 15px 50px 0px rgb(36 51 68 / 15%);
	color: #243344;
	cursor: pointer;
	background-color: #f4c03b;

	&:hover {
		color: #f4c03b;
		background-color: #ffffff;
		box-shadow: 0px 10px 10px 0px rgb(36 51 68 / 10%);
		transform: none;
		border-color: rgba(167, 167, 167, 0.1);
	}
`;

const FloatingPhone = () => {
	return (
		<PhoneIcon className="d-flex justify-content-center align-items-center">
			<a href="tel:+201000073381">
				<BiPhoneCall size="2em" color="#243344" />
			</a>
		</PhoneIcon>
	);
};

export default FloatingPhone;
