import { Container, Row, Col, Image } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import SocialMedia from '../Social/Social';

// Import Images
// import Logo from '/icon.png';

const FooterContainer = styled.footer`
	background-color: #17172d;
`;

const AboutGhadan = styled.p`
	font-size: 14px;
	color: #718090;
	line-height: 2;
	text-align: justify;
`;

const Copyright = styled.div`
	background: #03031b;
	color: #646470;
	font-size: 14px;
`;

const FooterLink = styled(Link)`
	display: block;
	font-size: 14px;
	color: #718090;
	margin-bottom: 15px;
	transition: all 0.2s;

	&:hover {
		color: #fff;
		cursor: pointer;
		text-decoration: none;
	}
`;

const Title = styled.h2`
	font-size: 22px;
	font-weight: 300;
	color: #fff;
	margin-bottom: 35px;
`;

const Detail = styled.p`
	font-size: 14px;
	color: #718090;
	line-height: 2;
`;

const Footer = () => {
	return (
		<FooterContainer>
			<Container className="py-5">
				<Row>
					<Col md={3}>
						<div className="pr-lg-3 pr-md-3">
							<div className="d-flex justify-content-center align-content-start mb-2">
								<Link to="/" exact>
									<Image src="/ghadan.webp" fluid />
								</Link>
							</div>
							<AboutGhadan className="mb-4">
								Ghadan Company Started in 2009 as a promoting marketing
								organization in Egypt, and in short time invaded many of the
								business regions, specially the minerals and Cement business.
								<br />
								We developed our business of salt and Cement rapidly, and
								succeeded to be among the well-known trade Companies in Egypt.
							</AboutGhadan>
						</div>
					</Col>
					<Col md={2}>
						<Title className="my-4">Quick Links</Title>
						<ul className="list-unstyled">
							<FooterLink to="/" exact>
								Home
							</FooterLink>
							<FooterLink to="/about">About Us</FooterLink>
							<FooterLink to="/contact">Contact Us</FooterLink>
						</ul>
					</Col>
					<Col md={2}>
						<Title className="my-4">Salt Products</Title>
						<ul className="list-unstyled">
							<FooterLink to="/products/rock-salt">
								Deicing Rock Salt
							</FooterLink>
							<FooterLink to="/products/marine-salt">Marine Salt</FooterLink>
							<FooterLink to="/products/industrial-salt">
								Industrial Salt
							</FooterLink>
							<FooterLink to="/products/treated-salt">Treated Salt</FooterLink>
							<FooterLink to="/products/edible-salt">Edible Salt</FooterLink>
							<FooterLink to="/products/tablet-salt">Tablet Salt</FooterLink>
						</ul>
					</Col>
					<Col md={2}>
						<Title className="my-4">Cement {'&'} Clinker</Title>
						<ul className="list-unstyled">
							<FooterLink to="/products/ordinary-portland-clinker">
								Ordinary Portland Clinker
							</FooterLink>
							<FooterLink to="/products/ordinary-portland-cement">
								Ordinary Portland cement
							</FooterLink>
							<FooterLink to="/products/masonry-cement">
								Masonry cement
							</FooterLink>
							<FooterLink to="/products/low-alkali-clinker">
								Low Alkali Clinker
							</FooterLink>
							<FooterLink to="/products/low-alkali-cement">
								Low Alkali Cement
							</FooterLink>
							<FooterLink to="/products/limestone-cement">
								Limestone cement
							</FooterLink>
						</ul>
					</Col>
					<Col md={3}>
						<Title className="my-4">Contact Us</Title>
						<Row>
							<Col
								xs={3}
								className="d-flex justify-content-center align-items-start"
							>
								<Image className="pt-2" src="/images/map-marker.png" fluid />
							</Col>
							<Col>
								<Detail>
									1st Kafrawy st. New Demitta, <br /> Demitta, Egypt
								</Detail>
							</Col>
						</Row>
						<Row>
							<Col
								xs={3}
								className="d-flex justify-content-center align-items-start"
							>
								<Image className="pt-2" src="/images/phone.png" fluid />
							</Col>
							<Col>
								<Detail>
									<a href="tel:+201000073381" style={{ color: '#718090' }}>
										+2 010 000 73381
									</a>
									<br /> info@ghadan.co
								</Detail>
							</Col>
						</Row>
						<div>
							<SocialMedia />
						</div>
					</Col>
				</Row>
			</Container>
			<Copyright className="d-flex align-items-center justify-content-center py-3">
				<p className="text-center text-capitalize mb-0 py-1">
					Copyright © {new Date().getFullYear().toString()}{' '}
					<strong>Ghadan</strong> All Rights Reserved
				</p>
			</Copyright>
		</FooterContainer>
	);
};

export default Footer;
