import ScrollToTop from 'react-scroll-up';
import { AiOutlineArrowUp } from 'react-icons/ai';
import styled from 'styled-components/macro';

const UpIcon = styled.div`
	width: 40px;
	height: 40px;
	font-size: 13px;
	color: #ffffff;
	background-color: #243344;
	padding: 12px;
	transform: scale(1.2);
	border-radius: 0px;
	transition: all 0.4s;
	border: 1px solid rgba(167, 167, 167, 0.2);
	box-shadow: 0px 15px 50px 0px rgb(36 51 68 / 15%);

	&:hover {
		color: #243344;
		background-color: #f4c03b;
		box-shadow: 0px 10px 10px 0px rgb(36 51 68 / 10%);
		bottom: 35px;
		transform: none;
		border-color: rgba(167, 167, 167, 0.1);
	}
`;

const ScrollToTopIcon = () => {
	return (
		<ScrollToTop
			showUnder={160}
			duration={600}
			style={{ right: '2%', bottom: '5%' }}
		>
			<UpIcon className="d-flex justify-content-center align-items-center">
				<AiOutlineArrowUp size="1.5em" />
			</UpIcon>
		</ScrollToTop>
	);
};

export default ScrollToTopIcon;
