import { Fragment } from 'react';
import './App.css';

import AppRouter from './routes/routes';

const App = () => {
	return (
		<Fragment>
			<AppRouter />
		</Fragment>
	);
};

export default App;
