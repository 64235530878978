import { Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components/macro";
import cementProducts from "../../assets/files/Cement_Products_compressed.pdf";
import clinkerProducts from "../../assets/files/Clinker_Products_compressed.pdf";

const Heading = styled.h6`
  font-weight: 400;
  font-size: 14px;
  color: #081624;
  margin-bottom: 0;
`;

const Detail = styled.p`
  font-size: 14px;
  color: #718090;
  margin-bottom: 0;
`;

const InfoItem = styled.div`
  width: 188px;
  padding: 14px;
`;

const NavItemLink = styled(NavLink)`
  display: block;
  padding: 32px 16px;
  font-size: 15px;
  font-weight: 600;
  color: #081624;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 7px;
    left: 0;
    bottom: 0;
    background: #ffc000;
    transition: all 0.4s;
  }

  &:hover {
    color: #081624;
    text-decoration: none;
    &::after {
      width: 100%;
    }
  }

  @media (max-width: 575.98px) {
    padding: 5px 10px;
    margin: 2px 5px;
    text-decoration: none;
    color: #fff;
    background-color: transparent;
    transition: all 0.2s;

    &:hover {
      background: #25394c;
      border-radius: 6px;
      color: #fff;
      text-decoration: none;
      outline: none;
      &::after {
        width: 0%;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: 5px 10px;
    margin: 2px 5px;
    text-decoration: none;
    color: #fff;
    background-color: transparent;
    transition: all 0.2s;

    &:hover {
      background: #25394c;
      border-radius: 6px;
      color: #fff;
      text-decoration: none;
      outline: none;
      &::after {
        width: 0%;
      }
    }
  }
`;

const Navigation = () => {
  return (
    <div>
      <Navbar bg="white" expand="md" className="py-lg-0 py-md-0">
        <Navbar.Brand as={NavLink} to="/" exact>
          <div style={{ width: "150px" }}>
            <Image src="/ghadan.webp" fluid />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavItemLink as={NavLink} to="/" exact>
              Home
            </NavItemLink>
            <NavItemLink as={NavLink} to="/about">
              About
            </NavItemLink>
            <NavDropdown
              style={{ color: "#081624" }}
              title="Products"
              id="basic-nav-dropdown"
            >
              <div className="dropdown-container">
                <ul className="list-unstyled outer-custom-dropdown">
                  <li>
                    <ul className="list-unstyled inner-custom-dropdown">
                      <li>
                        <NavDropdown.Item
                          as={Link}
                          to="/products"
                          className="dropdown-title text-capitalize"
                        >
                          Salt
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item as={Link} to="/products/rock-salt">
                          Deicing Rock Salt
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item as={Link} to="/products/marine-salt">
                          Marine Salt
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item
                          as={Link}
                          to="/products/industrial-salt"
                        >
                          Industrial Salt
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item as={Link} to="/products/treated-salt">
                          Treated Salt
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item as={Link} to="/products/edible-salt">
                          Edible Salt
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item as={Link} to="/products/tablet-salt">
                          Tablet Salt
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item
                          as={Link}
                          to="/products/pulverized-salt"
                        >
                          Pulverized Salt
                        </NavDropdown.Item>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled outer-custom-dropdown mx-lg-4">
                  <li>
                    <ul className="list-unstyled inner-custom-dropdown">
                      <li>
                        <NavDropdown.Item
                          as={Link}
                          to="/products"
                          className="dropdown-title text-capitalize"
                        >
                          Fertilizers
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item as={Link} to="/products/dap">
                          Diammonium Phosphate
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item as={Link} to="/products/tsp">
                          Triple Super Phosphate
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item as={Link} to="/products/gssp">
                          Single Super Phosphate
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item as={Link} to="/products/urea">
                          UREA
                        </NavDropdown.Item>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="list-unstyled outer-custom-dropdown mx-lg-4">
                  <li>
                    <ul className="list-unstyled inner-custom-dropdown">
                      <li>
                        <NavDropdown.Item
                          className="dropdown-title text-capitalize"
                          as={Link}
                          to="/products"
                        >
                          cement {"&"} clinker
                        </NavDropdown.Item>
                      </li>

                      <li>
                        <NavDropdown.Item
                          href={cementProducts}
                          traget="_blank"
                          download
                        >
                          Cement:
                          <br />
                          Available quality brochure.
                          <span className="text-primary d-block">Download</span>
                          {/* <a
                            traget="_blank"
                            href={cementProducts}
                            className="text-primary d-block"
                            download
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          > */}
                          {/* </a> */}
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item
                          href={clinkerProducts}
                          traget="_blank"
                          download
                        >
                          Clinker:
                          <br />
                          Available quality brochure.
                          <span className="text-primary d-block">Download</span>
                        </NavDropdown.Item>
                      </li>
                      {/* <li>
												<NavDropdown.Item
													as={Link}
													to="/products/masonry-cement"
												>
													Masonry cement
												</NavDropdown.Item>
											</li>
											<li>
												<NavDropdown.Item
													as={Link}
													to="/products/low-alkali-clinker"
												>
													Low Alkali Clinker
												</NavDropdown.Item>
											</li>
											<li>
												<NavDropdown.Item
													as={Link}
													to="/products/low-alkali-cement"
												>
													Low Alkali Cement
												</NavDropdown.Item>
											</li>
											<li>
												<NavDropdown.Item
													as={Link}
													to="/products/limestone-cement"
												>
													Limestone cement
												</NavDropdown.Item>
											</li> */}
                    </ul>
                  </li>
                </ul>
              </div>
            </NavDropdown>
            <NavItemLink as={NavLink} to="/faq">
              FAQ
            </NavItemLink>
            <NavItemLink as={NavLink} to="/blog">
              Blog
            </NavItemLink>
            <NavItemLink as={NavLink} to="/contact">
              Contact
            </NavItemLink>
          </Nav>
          <>
            <div className="d-none d-sm-none d-md-flex d-md-flex d-lg-flex">
              <InfoItem className="d-flex flex-row">
                <div className="d-flex justify-content-center align-items-center mr-2">
                  <Image src="/images/map-marker.png" fluid />
                </div>
                <div className="caption">
                  <Heading>1st Kafrawy st.</Heading>
                  <Detail>New Demitta, Egypt</Detail>
                </div>
              </InfoItem>
              <InfoItem className="d-flex flex-row">
                <div className="d-flex justify-content-center align-items-center mr-2">
                  <Image src="/images/phone.png" fluid />
                </div>
                <div className="caption">
                  <Heading>+2 010 000 73381</Heading>
                  <Detail>info@ghadan.co</Detail>
                </div>
              </InfoItem>
            </div>
          </>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
